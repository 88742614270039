<template>
  <div>
    <div class="w3-col w3-left-align">
      <content-header v-bind:title="currentDesk.name"></content-header>
      <div class="w3-right w3-margin-right flow-margin-top">
        <i
          class="fa fa-plus flow-blue-color w3-xlarge cursor-pointer w3-margin-right"
          @click="openNewForm = true"
          :title="$t('addNewPersonAppointment')"
        ></i>
      </div>
    </div>
  </div>

  <div class="content-sidebars sidebars-60-40">
    <div
      v-if="openNewForm && !openVisitCard"
      class="w3-container content-sidebar-right"
    >
      <i
        class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top"
        @click="openNewForm = false"
      ></i>
      <br />
      <new-visit
        :onClose="
          () => {
            openNewForm = false;
          }
        "
        :callbackAddVisit="addNewVisit"
        :saveVisitEnabled="saveVisitEnabled"
      ></new-visit>
    </div>

    <div
      v-bind:class="openVisitCard || openNewForm ? 'content-sidebar-left' : ''"
    >
      <!--  Visit side card-->

      <div v-if="openVisitCard" class="w3-container content-sidebar-right">
        <i
          class="fa fa-times w3-left cursor-pointer w3-xlarge w3-text-gray flow-margin-top"
          @click="openVisitCard = false"
        ></i>
        <br />
        <Visit-side-card
          class="flow-padding-top"
          @refresh-visitor-list="getVisits"
          :selectedVisit="selectedVisit"
          :onClose="
            () => {
              openVisitCard = false;
            }
          "
          :callback-update-appointment="updateAppointment"
          :callback-cancel="cancelAppointment"
        ></Visit-side-card>
      </div>

      <div
        class="flow-card blue-top w3-col w3-padding qpp-margin-sides-5pc flow-margin-top  w-90pc bottom w3-margin-bottom"
      >
        <loading v-bind:dspin="!ongoingLoaded"></loading>
        <OngoingVisit
          v-show="ongoingLoaded"
          :ongoing="ongoing"
          :endVisit="endVisit"
          :holdAppointment="holdAppointment"
          :recallVisitor="recallVisitor"
        ></OngoingVisit>
      </div>

      <!--FILTERS-->
      <div class="w3-container w3-margin-top w-90pc qpp-margin-sides-5pc">
        <div class="w3-left">
          <!--        statuses-->
          <span
            v-for="status in getAllStatuses()"
            v-bind:key="status"
            class="w3-ripple flow-position-relative visits-filter"
            @click="selectStatusFilter(status)"
            v-bind:class="
              filters['status'] && filters['status'].includes(status)
                ? 'active'
                : ''
            "
          >
            <visit-status
              class="w3-large w3-show-inline-block w3-margin-right cursor-pointer transform-animation"
              :status="status"
            >
            </visit-status>
            <i
              class="active-check fa fa-check-circle flow-green-active-color w3-animate-opacity w3-large"
            >
            </i>
          </span>

          <!--        Domain types-->

          <span>
            <span class="divider w3-xlarge flow-margin-right">|</span>

            <span
              class="w3-ripple flow-position-relative visits-filter"
              id="show-domains"
              v-bind:class="
                filters['domains'] && filters['domains'].length > 0
                  ? 'active'
                  : 'inactive'
              "
              v-bind:title="$t('chooseDomain')"
              @click="showDomains = !showDomains"
            >
              <span
                class="flow-fa-icon w3-large w3-show-inline-block w3-margin-right cursor-pointer transform-animation w3-border"
              >
                <i class="fa w3-large fa-align-justify"></i>
              </span>

              <i
                class="active-check fa fa-check-circle flow-green-active-color w3-animate-opacity w3-large"
              ></i>
            </span>
          </span>

          <div
            class="domain-list flow-btn-checkbox-group w3-padding"
            v-show="showDomains"
            id="domain-list"
          >
            <div v-if="domainTypes.length === 0">
              {{ $t("noDomainsLinked") }}
            </div>
            <div
              v-else
              class="w3-container w3-btn cursor-pointer w3-ripple flow-btn-checkbox w3-animate-opacity w3-left w-100pc zero-left zero-top"
              v-for="dt in domainTypes"
              :key="dt.id"
              v-bind:class="
                filters['domains'] && filters['domains'].includes(dt.id)
                  ? 'active'
                  : 'inactive'
              "
              @click="selectDTFilter(dt.id)"
            >
              <div class="w3-col flow-btn-checkbox-icon">
                <i class="fa  w3-left"></i>
              </div>
              <div class="w3-col flow-btn-checkbox-label">
                <span>{{ dt.name }}</span>
              </div>
            </div>
          </div>

          <span>
            <span class="divider w3-xlarge flow-margin-right">|</span>

            <span
              class="w3-ripple flow-position-relative visits-filter"
              v-bind:title="$t('myVisitsOnly')"
              v-bind:class="myVisitsOnly ? 'active' : 'not-active'"
              @click="showMyVisitors"
            >
              <span
                class="flow-fa-icon w3-large w3-show-inline-block w3-margin-right cursor-pointer transform-animation w3-border"
              >
                <i class="fa w3-large fa-user"></i>
              </span>

              <i
                class="active-check fa fa-check-circle flow-green-active-color w3-animate-opacity w3-large"
              ></i>
            </span>
          </span>
        </div>

        <!--      visit count-->
        <span
          class="table-count w3-col w-30pc w3-right w3-right-align flow-blue-color w3-medium w3-padding"
          v-html="$t('showingAppointments', { number: visits.length })"
        >
        </span>
      </div>

      <!--  visits table-->
      <div
        class=" w3-col w3-padding flow-margin-top w-90pc qpp-margin-sides-5pc"
      >
        <!--        <loading v-bind:dspin="!contentLoaded"></loading>-->

        <!--        <visits-for-caller v-show="contentLoaded"-->
        <visits-for-caller
          :visits="visits"
          :onSelectCallback="openVisit"
          :onPriorityChanged="
            () => {
              this.getVisits();
            }
          "
          :openCard="openVisitCard || openNewForm"
          :call-visitor="callVisitor"
          :print-ticket="printTicket"
          :set-as-arrived-and-print-ticket="setAsArrivedAndPrintTicket"
          :ongoing="ongoing.currentVisits && ongoing.currentVisits.length !== 0"
        ></visits-for-caller>
      </div>
    </div>
  </div>
</template>
<script>
import OngoingVisit from "../visits/OngoingVisit";
import VisitsForCaller from "../visits/VisitsForCaller";
import VisitStatus from "../../common/VisitStatus";
import VisitSideCard from "../visits/VisitSideCard";
import ContentHeader from "../../common/ContentHeader";
import NewVisit from "../visits/NewVisit";
import Loading from "../../common/Loading";

export default {
  name: "VisitList",
  components: {
    OngoingVisit,
    VisitsForCaller,
    VisitStatus,
    VisitSideCard,
    NewVisit,
    ContentHeader,
    Loading
  },
  data() {
    return {
      contentLoaded: false,
      ongoingLoaded: false,
      showDomains: false,
      myVisitsOnly: false,
      visits: [],
      currentUserId: null,
      currentDeskId: null,
      saveVisitEnabled: true,
      selectedVisit: {},
      openVisitCard: false,
      filters: {
        contact: null,
        status: ["ARRIVED", "SERVING"],
        domains: []
      },
      ongoing: {},
      openNewForm: false,
      currentDesk: {},
      refreshInterval: 30000,
      timeOutId: undefined,
      domainTypes: []
    };
  },
  mounted() {
    this.currentUserId = this.$store.getters["users/getCurrentUser"].id;

    if (parseInt(this.$route.params.userId) !== this.currentUserId) {
      this.$notify.notifyError({
        title: this.$t("failed"),
        message: this.$t("desk-user-not-linked")
      });
      return;
    }

    this.currentDeskId = this.$route.params.deskId;
    this.getRefreshInterval();
    this.getDesk();
    // this.getVisits();
    this.getCurrentUserDT();
    // this.getOngoing();
    this.refreshAndSetInterval();

    // Hide domains dropdown on clicking anywhere on screen
    var domainList = document.getElementById("domain-list");
    var showDomainsDropdown = document.getElementById("show-domains");
    var app = this;
    document.addEventListener(
      "click",
      function() {
        app.showDomains = false;
      },
      false
    );
    domainList.addEventListener(
      "click",
      function(ev) {
        ev.stopPropagation();
      },
      false
    );
    showDomainsDropdown.addEventListener(
      "click",
      function(ev) {
        ev.stopPropagation();
      },
      false
    );
  },
  beforeUnmount() {
    const app = this;
    clearTimeout(app.timeOutId);
  },
  methods: {
    refreshAndSetInterval() {
      const app = this;
      if (app.timeOutId) {
        clearTimeout(app.timeOutId);
      }
      app
        .getOngoing()
        .then(() => {
          app
            .getVisits()
            .then(() => {
              app.timeOutId = setTimeout(
                app.refreshAndSetInterval,
                app.refreshInterval
              );
            })
            .catch(() => {
              app.timeOutId = setTimeout(
                app.refreshAndSetInterval,
                app.refreshInterval
              );
            });
        })
        .catch(() => {
          app.timeOutId = setTimeout(
            app.refreshAndSetInterval,
            app.refreshInterval
          );
        });
    },
    getRefreshInterval() {
      const app = this;
      app.$restClient.configuration.getVisitRefreshProperty().then(response => {
        let interval = response.data;
        if (!isNaN(interval) && interval >= 10000) {
          app.refreshInterval = interval;
        }
      });
    },
    getCurrentUserDT() {
      const app = this;
      app.$restClient.domainTypes
        .currentUserDT()
        .then(res => {
          app.domainTypes = res.data;
          this.$helper.sortByKey(app.domainTypes, "name");
        })
        .catch(() => {
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    getDesk() {
      const app = this;
      app.$restClient.desks
        .get(this.currentDeskId)
        .then(res => {
          app.currentDesk = res.data;
        })
        .catch(() => {
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    addNewVisit(visit) {
      const app = this;
      app.saveVisitEnabled = false;
      let payload = {
        deskId: app.currentDeskId,
        userId: app.currentUserId
      };
      app.$restClient.visits
        .add(visit, payload)
        .then(
          setTimeout(() => {
            app.openNewForm = false;
            app.$notify.notifySuccess({
              title: app.$t("success"),
              message: app.$t("added-success")
            });
            app.getVisits();
            app.getOngoing();
            app.saveVisitEnabled = true;
          }, 1000)
        )
        .catch(() => {
          app.saveVisitEnabled = true;
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    callVisitor(visitId) {
      const app = this;
      app.$restClient.visits
        .call({
          deskId: app.currentDeskId,
          userId: app.currentUserId,
          visitId: visitId
        })
        .then(function() {
          app.getOngoing();
          app.getVisits();
          if (
            app.selectedVisit.visitId === visitId &&
            app.openVisitCard === true
          ) {
            app.openVisit(
              app.selectedVisit.visitId,
              app.selectedVisit.visitor.id
            );
          }
        })
        .catch(function(er) {
          console.log(er);
          app.getOngoing();
          app.getVisits();
          if (er.response.status === 422) {
            app.$notify.notifyError({
              title: app.$t("failed"),
              message: app.$t("visitor-already-called")
            });
          } else {
            app.$notify.notifyError({ title: app.$t("failed") });
          }
        });
    },

    printTicket(visitId, checkin=false) {
      const app = this;
      console.log(app.currentDeskId);
      app.$restClient.visits
        .printTicket({
          visitId: visitId,
          deskId: app.currentDeskId
        })
        .then(function() {
          if (
            app.selectedVisit.visitId === visitId &&
            app.openVisitCard === true
          ) {
            app.openVisit(
              app.selectedVisit.visitId,
              app.selectedVisit.visitor.id
            );
          }
          app.$notify.notifySuccess({
            title: app.$t("success"),
            message: app.$t("print-success")
          });
        })
        .catch(function(er) {
          console.log(er);
          if (er.response.status === 422) {
            app.$notify.notifyError({
              title: app.$t("failed"),
              message: app.$t("print-ticket-failed")
            });
          } else if (
            er.response.status === 404 &&
            er.response.data.message === "PRINTER_NOT_FOUND"
          ) {
						if(checkin) {
							console.log("No printer linked to this space")
						} else {
							app.$notify.notifyError({
								title: app.$t("failed"),
								message: app.$t("no-printer-linked")
							});
						}
          } else {
            app.$notify.notifyError({
              title: app.$t("failed"),
              message: app.$t("something-went-wrong")
            });
          }
        });
    },

    setAsArrivedAndPrintTicket(visitId) {
      this.$restClient.visits
        .setStatusArrived({
          visitId: visitId,
          deskId: this.currentDeskId,
          userId: this.currentUserId
        })
        .then(() => {
          this.$notify.notifySuccess({
            title: this.$t("success"),
            message: this.$t("visitor-in-waiting-room")
          });
          this.printTicket(visitId, true);
          this.getVisits();
        })
        .catch(() => {
          this.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    cancelAppointment() {
      const app = this;
      app.$restClient.visits
        .cancel({
          deskId: app.currentDeskId,
          userId: app.currentUserId,
          visitId: app.selectedVisit.visitId
        })
        .then(function() {
          app.getVisits();
          app.openVisitCard = false;
        })
        .catch(function(er) {
          console.log(er);
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    updateAppointment(payload) {
      const app = this;
      payload["visitId"] = app.selectedVisit.visitId;
      app.$restClient.visits
        .update(payload, {
          deskId: app.currentDeskId,
          userId: app.currentUserId
        })
        .then(
          setTimeout(() => {
            app.getVisits();
            app.getOngoing();
            app.openVisit(
              app.selectedVisit.visitId,
              app.selectedVisit.visitor.id
            );
          }, 1000)
        )
        .catch(function(er) {
          console.log(er);
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    getAllStatuses() {
      return ["ENDED", "SERVING", "ARRIVED", "NOT_ARRIVED"];
    },
    openVisit(visitId, visitorId) {
      this.openVisitCard = true;
      this.openNewForm = false;

      const app = this;
      app.$restClient.visits
        .selected({
          deskId: app.currentDeskId,
          userId: app.currentUserId,
          visitorId: visitorId,
          visitId: visitId
        })
        .then(function(res) {
          app.selectedVisit = res.data;
          app.selectedVisit["visitId"] = visitId;

          let eID_valid;
          var today = new Date();
          var expiry = new Date(app.selectedVisit.visitor.eIDExpiry);

          eID_valid = expiry > today || expiry === today;
          if (app.selectedVisit.visitor.eID === null) {
            app.selectedVisit["loggedInVia"] = "Niet-eID";
            app.selectedVisit["editable"] = true;
          } else {
            app.selectedVisit["editable"] = false;
            if (!eID_valid) {
              app.selectedVisit["eIdColor"] = "red-input";
              app.selectedVisit["loggedInVia"] = "eID (ongeldig)"; //color red
            } else {
              if (app.selectedVisit.visitor.nonEER === false) {
                app.selectedVisit["loggedInVia"] = "eID (geldig)";
              } else {
                app.selectedVisit["eIdColor"] = "yellow-input";
                app.selectedVisit["loggedInVia"] = "eID (geldig)"; //color yellow
              }
            }
          }
        })
        .catch(function(er) {
          console.log(er);
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    showMyVisitors() {
      this.myVisitsOnly = !this.myVisitsOnly;

      if (this.myVisitsOnly === true) {
        this.filters["contact"] = this.currentUserId;
      } else {
        this.filters["contact"] = null;
      }

      this.getVisits();
    },
    selectStatusFilter(status) {
      let selectedStatuses = this.filters["status"];

      let index = selectedStatuses.indexOf(status);

      if (index > -1) {
        selectedStatuses.splice(index, 1);
      } else {
        selectedStatuses.push(status);
      }
      this.filters["status"] = selectedStatuses;

      this.getVisits();
    },
    selectDTFilter(dt) {
      let selectedDTs = this.filters["domains"];

      let index = selectedDTs.indexOf(dt);

      if (index > -1) {
        selectedDTs.splice(index, 1);
      } else {
        selectedDTs.push(dt);
      }
      this.filters["domains"] = selectedDTs;

      this.getVisits();
    },
    getOngoing() {
      const app = this;
      //app.ongoingLoaded;

      return app.$restClient.visits
        .ongoing({
          deskId: this.currentDeskId,
          userId: this.currentUserId
        })
        .then(function(res) {
          app.ongoing = res.data;
          app.ongoingLoaded = true;
        })
        .catch(function(er) {
          if (er.response.status === 404) {
            app.ongoing = {};
            app.ongoingLoaded = true;
          }
          if (er.response.status === 500) {
            app.$notify.notifyError({ title: app.$t("failed") });
          }
        });
    },
    endVisit() {
      const app = this;
      app.$restClient.visits
        .end({
          deskId: app.currentDeskId,
          userId: app.currentUserId,
          visitId: app.ongoing.currentVisits[0].visitId,
          isGroupVisit: app.ongoing.currentVisits.length > 1
        })
        .then(function() {
          app.ongoing = {};
          app.getVisits();
        })
        .catch(function(er) {
          console.log(er);
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    holdAppointment() {
      const app = this;
      let currentVisit = app.ongoing.currentVisits[0].visitId;
      app.$restClient.visits
        .setStatusArrived({
          deskId: app.currentDeskId,
          userId: app.currentUserId,
          visitId: currentVisit
        })
        .then(function() {
          app.ongoing = {};
          app.$restClient.visits
            .setVisitPriority(currentVisit, true)
            .then(function() {
              app.getVisits();
            })
            .catch(function(er) {
              console.log("Could not assign priority");
              console.log(er);
            });
        })
        .catch(function(er) {
          console.log(er);
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    recallVisitor() {
      const app = this;
      app.$restClient.visits
        .recall({
          deskId: app.currentDeskId,
          userId: app.currentUserId,
          visitId: app.ongoing.currentVisits[0].visitId
        })
        .then(function() {
          app.getVisits();
          app.$notify.notifySuccess({
            title: app.$t("success"),
            message: app.$t("call-success")
          });
        })
        .catch(function(er) {
          console.log(er);
          app.$notify.notifyError({ title: app.$t("failed") });
        });
    },
    getVisits() {
      const app = this;
      let query = app.createQueryString();
      app.contentLoaded = false;

      app.currentDeskId = app.$route.params.deskId;
      return app.$restClient.visits
        .all({
          deskId: this.currentDeskId,
          userId: this.currentUserId,
          query: query
        })
        .then(function(res) {
          app.visits = res.data;
          app.contentLoaded = true;
        })
        .catch(function(er) {
          console.log(er);
          if (er.response.status === 401) {
            app.$notify.notifyError({
              title: app.$t("failed"),
              message: app.$t("desk-user-not-linked")
            });
          } else {
            app.$notify.notifyError({ title: app.$t("failed") });
          }
        });
    },

    createQueryString() {
      let contact = this.filters["contact"];
      let statuses = this.filters["status"];
      let domains = this.filters["domains"];

      let query = "";

      if (contact !== null) {
        query += "contact=" + contact;
      }

      if (statuses.length > 0) {
        statuses.forEach(function(s) {
          if (query === "") {
            query += "status=" + s;
          } else {
            query += "&status=" + s;
          }
        });
      }

      if (domains.length > 0) {
        domains.forEach(function(s) {
          if (query === "") {
            query += "domainType=" + s;
          } else {
            query += "&domainType=" + s;
          }
        });
      }

      return query;
    }
  }
};
</script>
