<template>
  <div class="w3-left-align">
    <i class="fa fa-arrows-alt flow-blue-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('updateInstruction')"></content-header>
    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <p class=" zero-top w-70pc">
          <label class="w3-left flow-blue-color ">{{ $t("walking-ins-domain") }}</label>
          <flow-select :callback="setSelectedDomain" :items="domains"
                       :preSelectedItem="instruction.domain"
                       :valueKey="'name'"
                       :valueKeyIndex="'id'"
                       class="w3-border w3-round-medium"
                       type="text">
          </flow-select>
        </p>
      </div>

      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <p class=" zero-top w-70pc">
          <label class="w3-left flow-blue-color ">{{ $t("regime") }}</label>
          <flow-select :callback="setSelectedRegime" :items="regimes"
                       :preSelectedItem="instruction.regime"
                       :valueKey="'name'"
                       :valueKeyIndex="'id'"
                       class="w3-border w3-round-medium"
                       type="text">
          </flow-select>
        </p>
      </div>

      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <span v-if="showKioskError"
              class="w3-text-red qpp-field-error-message qpp-field-error-message-domain">{{ $t("kiosk-is-mandatory") }}</span>
        <p class=" zero-top w-70pc">
          <label class="w3-left flow-blue-color ">{{ $t("kiosk") }}</label>
          <flow-select :callback="setSelectedKiosk" :items="kiosks"
                       :preSelectedItem="instruction.kiosk"
                       :valueKey="'username'"
                       :valueKeyIndex="'id'"
                       class="w3-border w3-round-medium"
                       type="text">
          </flow-select>
        </p>
      </div>

      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <p class=" zero-top w-70pc">
          <label class="w3-left flow-blue-color ">{{ $t("destination") }}</label>
          <flow-select :callback="setSelectedRoom" :items="waitingRooms"
                       :preSelectedItem="instruction.waitingRoom"
                       :valueKey="'name'"
                       :valueKeyIndex="'id'"
                       class="w3-border w3-round-medium"
                       type="text">
          </flow-select>
        </p>
      </div>

      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <p class=" zero-top w-70pc">
          <label class="w3-left flow-blue-color ">{{ $t("standard-text") }}</label>
          <flow-select :callback="setSelectedStandardText" :items="standardTexts"
                       :preSelectedItem="currentInstruction.standardText"
                       :valueKey="'value'"
                       :valueKeyIndex="'key'"
                       class="w3-border w3-round-medium"
                       type="text">
          </flow-select>
        </p>
      </div>

      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <p class=" zero-top w-70pc">
          <label class="flow-blue-color">{{$t("register-appointment") }}</label>
          <br>
          <input type="checkbox" id="appointment-signin" name="" class="w3-check"
                 v-model="currentInstruction.registerAppointment">
        </p>
      </div>

      <div class="w3-container w3-block flow-form-inline-input flow-margin-bottom" style="padding-bottom: 10px">
        <div class=" zero-top w-38pc" style="display: inline-block">
          <label class="w3-left flow-blue-color ">{{ $t("validFrom") }}</label>
          <input type="date" id=valid-from name="" class="w-80pc"
                 v-model="currentInstruction.validFrom" style="">
        </div>
        <div class=" zero-top w-38pc" style="display: inline-block">
          <label class="w3-left flow-blue-color ">{{ $t("validUntil") }}</label>
          <input type="date" id=valid-until name="" class="w-80pc"
                 v-model="currentInstruction.validUntil" style="">
        </div>
      </div>



      <div class="w3-container zero-bottom w3-block flow-form-inline-input">
        <p class=" zero-top w-70pc">
          <label class="w3-left flow-blue-color ">{{ $t("direction") }}</label>

          <button class="qpp-walkIns-direction" @click="updateAngle">
            <img v-if="angles[currentAngleIndex] != null" :src="require('@/assets/images/arrow.png')"
                 :style="{transform: 'rotate('+ angles[currentAngleIndex] +'deg)'}"/></button>
        </p>
      </div>

      <div class="zero-bottom zero-top">
        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="updateInstruction">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="deleteInstruction">
          <i class="fa fa-trash w3-text-deep-orange">
            <span><b class="flow-margin-left">{{ $t('delete') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>
</template>
<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";

export default {
  name: 'EditWalkingInstruction',
  components: {ContentHeader, FlowSelect},
  emits: ['instructionUpdated'],
  props: ['instruction', 'onClose', 'domains', 'waitingRooms', 'kiosks', 'regimes', 'standardTexts'],
  data() {
    return {
      currentInstruction: {},
      npNameError: undefined,
      directions: [{name: 'top'}, {name: 'top right'}, {name: 'right'}, {name: 'bottom right'},
        {name: 'bottom'}, {name: 'bottom left'}, {name: 'left'}, {name: 'top left'}],
      angles: [0, 45, 90, 135, 180, -135, -90, -45, null],
      currentAngleIndex: 0,
      showKioskError: false
    }
  },
  mounted() {
    this.currentInstruction = Object.assign({}, this.instruction);
    this.currentInstruction.standardText = this.instruction.locale;
    this.currentAngleIndex = this.angles.indexOf(this.currentInstruction.arrowAngle) ;
  },
  watch: {
    instruction(newInstruction){
      this.currentInstruction = Object.assign({}, newInstruction);
    }
  },
  methods: {
    updateInstruction() {
      this.currentInstruction.arrowAngle = this.angles[this.currentAngleIndex];
      const app = this;
      app.showKioskError = false;
      if (!app.currentInstruction.kiosk || !app.currentInstruction.kiosk.id) {
        app.showKioskError = true;
        return;
      }
      app.$restClient.walkingInstructions.update(this.currentInstruction).then(response => {
        // eslint-disable-next-line no-unused-vars
        let data = response.data;
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t('modified-success')});
        this.$emit("instructionUpdated", data);
        app.onClose();
      }).catch(err => {
        if (err && err.response.status === 400)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('instruction-match-unique')});
        else if (err && err.response.status===427)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('validity-interval-taken')});
        else if (err && err.response.status === 428)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('missing-kiosk-id')});
        else if (err && err.response.status === 429)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('missing-walking-instruction-fields')});
        else if (err && err.response.status === 430)
          app.$notify.notifyError({title: this.$t("failed"), message: this.$t('missing-domain-or-regime')});
        else
          app.$notify.notifyError({title: this.$t("failed")});
      });

    },

    setSelectedDomain(domain) {
      this.currentInstruction.domain = domain;
    },

    setSelectedRegime(regime) {
      this.currentInstruction.regime = regime;
    },

    setSelectedKiosk(kiosk) {
      const app = this;
      app.showKioskError = false;
      this.currentInstruction.kiosk = kiosk;
      if (!app.currentInstruction.kiosk || !app.currentInstruction.kiosk.id) {
        app.showKioskError = true;
      }
    },

    setSelectedRoom(room) {
      this.currentInstruction.waitingRoom = room;
    },

    setSelectedImage(image) {
      this.currentInstruction.arrowAngle = image.name;
    },

    setSelectedStandardText(text) {
      this.currentInstruction.standardText = text;
    },

    updateAngle() {
      this.currentAngleIndex++;
      if (this.currentAngleIndex === 9) this.currentAngleIndex = 0;
    },

    deleteInstruction(){
      const app = this;
      app.$restClient.walkingInstructions.delete(app.currentInstruction.id).then( () => {
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
        app.$emit("refresh-instructions-list");
        app.onClose();
      }).catch(response => {
        console.log(response);
        app.$notify.notifyError({title: this.$t("failed"), message: this.$t("failed-to-delete")})
      })
    }

  }
}
</script>