<template>
  <div class="w3-container">
    <div class="w3-container flow-card blue-top w3-animate-opacity w-45pc w3-margin-top flow-float-left">

      <table class="w3-table w3-bordered flow-table">
        <thead class="flow-blue-color">
          <th>{{ $t("messages-dashboard") }}</th>
          <th></th>
        </thead>

        <tbody>
          <tr>
            <td class="w3-center flow-blue-color" colspan="8">
              <table class="w3-table w3-bordered flow-table">
                <tbody>
                  <tr v-if="messages.length === 0">
                    <td class="w3-center flow-blue-color">
                      <b class="font-size-1pt25em">{{ $t('noMessages') }}</b>
                    </td>
                  </tr>
                  <tr v-else v-for="message in messages" :key="message.id" v-show="contentLoaded">
                    <td class="flow-blue-color font-weight-bold vertical-align-initial"
                        :class="(message.messageImportance === 'HIGH') ? 'light-red-background' : ''">
                      {{ _formatDate(message.publicationDate) }}
                    </td>
                    <td class="flow-blue-color"
                        :class="(message.messageImportance === 'HIGH') ? 'light-red-background' : ''">
                      <div class="w3-margin-left font-weight-bold" v-html="message.title"></div>
                      <div class="w3-margin-left" v-html="message.description"></div>
                    </td>
                  </tr>
                  <tr>
                    <loading v-bind:dspin="!contentLoaded"></loading>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="w3-container w-50pc w3-margin-top flow-float-right">
      <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right" @click="goToHomePage">
        <i class="fa fa-home flow-green-active-color">
          <span><b class="flow-margin-left">{{ $t('home') }}</b></span>
        </i>
      </button>
    </div>
  </div>
</template>

<script>

import Loading from "@/components/common/Loading.vue";

export default {
  name: 'Dashboard',
  components: {Loading},

  data() {
    return {
      contentLoaded: false,
      messages: []
    }
  },

  mounted() {
    this.getAllMessages();
  },

  methods: {

    getAllMessages() {
      const app = this;
      app.texts = [];
      app.$restClient.messages.dashboardMessages().then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          app.messages = data;
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },

    _formatDate(publicationDate) {
        let _date = new Date(Date.parse(publicationDate));
        let month = '' + (_date.getMonth() + 1),
            day = '' + _date.getDate();

        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;

        return [day, month].join('/');
    },

    goToHomePage(){
      this.$router.push({name:"home"});
    },

  }

}
</script>