<template>
  <div class="w3-left-align">
    <Visit-status class="w3-show-inline-block flow-margin-right"
                  v-if="selectedVisitStatus"
                  :status="selectedVisitStatus">
    </Visit-status>
    <VisitorNameHeader :propsVisitor="visitor"></VisitorNameHeader>

    <div class="w3-margin-top w3-margin-bottom w3-left-align" v-if="selectedVisit && visitor">

      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">
        <div class="flow-flex-row">
          <div class="flow-padding-bottom zero-top flow-flex-col w3-left">

            <p class="flow-padding-bottom zero-top w-100pc flow-flex-col w3-left">
              <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("firstName") }}</label>
              <input v-model="visitor.firstName" class="w3-input w3-border w3-round-medium"
                     :disabled="!selectedVisit.editable"
                     type="text"
                     v-bind:placeholder="$t('firstName')"/>
            </p>

            <p class="flow-padding-bottom zero-top w-100pc flow-flex-col w3-left">
              <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("lastName") }}</label>
              <input v-model="visitor.lastName" class="w3-input w3-border w3-round-medium"
                     :disabled="!selectedVisit.editable"
                     type="text"
                     v-bind:placeholder="$t('lastName')"/>
            </p>

          </div>

          <div class="flow-padding-bottom zero-top flow-flex-col w3-right flex-align-end flex-1 flow-padding-left"
               v-if="visitor.photoFile && imageBaseString">
            <img class="visitor-photo" :src="'data:image/png;base64,'+imageBaseString" alt="photo" draggable="false">
          </div>
        </div>

        <p class="flow-padding-bottom zero-top w-100pc flow-flex-col w3-left">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("address") }}</label>
          <input v-model="address" class="w3-input w3-border w3-round-medium"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('address')"/>
        </p>

        <!--        <p class="flow-padding-bottom zero-top w-100pc flow-flex-col w3-left">-->
        <!--          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("salutation") }}</label>-->
        <!--          <input v-model="visitor.salutation" class="w3-input w3-border w3-round-medium"-->
        <!--                 disabled="disabled"-->
        <!--                 type="text"-->
        <!--                 v-bind:placeholder="$t('salutation')"/>-->
        <!--        </p>-->

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("nationalNumber") }}</label>
          <input v-model="visitor.nationalNumber" class="w3-input w3-border w3-round-medium"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('nationalNumber')"/>
        </p>

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("iklNumber") }}</label>
          <input v-model="visitor.iklNumber" class="w3-input w3-border w3-round-medium"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('iklNumber')"/>
        </p>

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("dob") }}</label>
          <input v-model="visitor.dob" class="w3-input w3-border w3-round-medium"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('dob')"/>
        </p>

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("nationality") }}</label>
          <input v-model="visitor.nationality" class="w3-input w3-border w3-round-medium"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('nationality')"/>
        </p>

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-left">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("preferredLanguage") }}</label>
          <input :value="visitLanguage" class="w3-input w3-border w3-round-medium"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('language')"/>
        </p>

        <p class="flow-padding-bottom zero-top w-45pc flow-flex-col w3-right">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("loggedInVia") }}</label>
          <input :value="selectedVisit.loggedInVia" class="w3-input w3-border w3-round-medium"
                 v-bind:class="selectedVisit.eIdColor"
                 disabled="disabled"
                 type="text"
                 v-bind:placeholder="$t('loggedInVia')"/>
        </p>

        <!--        <p class="flow-padding-bottom zero-top w-100pc flow-flex-col">-->
        <!--          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("walkingInstruction") }}</label>-->
        <!--          <textarea class="w3-input w3-border w3-round-medium"-->
        <!--                    disabled="disabled"-->
        <!--                    type="text"-->
        <!--                    v-bind:placeholder="$t('walkingInstruction')"/>-->
        <!--        </p>-->

        <p class="flow-padding-bottom zero-top w-100pc flow-flex-col">
          <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("visitorComment") }}</label>
          <textarea class="w3-input w3-border w3-round-medium"
                    type="text"
                    v-bind:placeholder="$t('visitorComment')"
                    v-model="visitor.comment"
                    style="resize: none;"/>
        </p>

        <!--    <div v-if="selectedVisit.editable">-->
        <div>
          <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                  @click.prevent="saveVisitor">
            <i class="fa fa-save flow-green-active-color">
              <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
            </i>
          </button>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import VisitStatus from "../../common/VisitStatus";
import VisitorNameHeader from "../../common/VisitorNameHeader";

export default {
  name: "EditVisitorForm",
  components: {VisitStatus, VisitorNameHeader},
  props: ['selectedVisit', "selectedVisitStatus", "visitLanguage"],

  data() {
    return {
      visitor: {},
      eIdColor: '',
      imageBaseString: ''
    }
  },
  computed: {
    address() {
      const {addressMunicipality, addressZip, addressStreetAndNumber} = this.visitor;

      const municipality = addressMunicipality ?? '';
      const zip = addressZip ?? '';
      const street = addressStreetAndNumber ?? '';

      if (!municipality && !zip && !street) {
        return '';
      }

      return `${street} ${zip} ${municipality}`;
    }
  },
  mounted() {
    this.eIdColor = '';
    this.visitor = this.selectedVisit.visitor;
  },
  watch: {
    visitor(newVal) {
      if (newVal) this.getPhotoContent();
    },
    selectedVisit(newVal) {
      this.visitor = newVal.visitor;
      this.visitor.comment = this.$helper.sanitizeHTML(this.visitor.comment);
    }
  },
  methods: {
    getPhotoContent() {
      if (this.visitor.photoFile) {
        let hexString = this.$helper.extractHexString(this.visitor.photoFile);
        this.imageBaseString = hexString ? Buffer.from(hexString, 'hex').toString('base64') : '';
      }
    },
    saveVisitor() {
      const app = this;
      app.$restClient.visitors.updateVisitor(app.visitor).then(() => {
        app.$notify.notifySuccess({title: this.$t("success"), message: this.$t("modified-success")});
        app.$emit("refresh-visitor-list");
      }).catch(exception => {
        console.log(exception);
        app.$notify.notifyError({title: app.$t("failed")});
      })
    }
  }
}
</script>
<style>
.red-input {
  background-color: rgba(255, 0, 0, 0.4);
}

.yellow-input {
  background-color: rgba(255, 255, 0, 0.4);
}

.visitor-photo {
  width: auto;
  height: 140px;
}
</style>