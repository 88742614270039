<template>
  <div class="w3-container content-sidebar-right"> <!-- class: layout of sidebar | shows sidebar when openNewForm = true and openDetailsForm = false -->
    <CloseButton :on-clicked="() => sideFormStatusEvent('close')" />
    <slot v-if="context === 'create'" name="create"></slot>
    <slot v-if="context === 'update'" name="update"></slot>
  </div>
</template>

<script>

import CloseButton from "@/components/common/buttons/CloseButton.vue";
import {mapActions} from "vuex";

export default {
  name: "SideForm",
  components: {CloseButton},
  props: {
    /**
     * the value of this determines if the sidebar is closed or not and in what context it is open.
     * <p>
     * if the value is set to "create" then it shows the CreateOpeningHourSideBar, if it is set to "update" then it shows the UpdateOpeningHourSideBar.
     */
    context: {
      type: String,
      required: true,
      default: "close"
    }
},
  methods: {
    ...mapActions({
      /**
       * In this component this event is used to let the screen component know that the form on the side should be closed
       */
      sideFormStatusEvent: "eventHandlers/sideFormStatusEvent"
    })
  }
}
</script>