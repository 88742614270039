module.exports = {
    "local": {
        "localhost:8081": {
            host: "http://localhost:7000",
            redirectUrl: "http://localhost:8081",
            colorScheme: "#006400", // dark green
            synapseApplicationId: "dev.api.qarts.eu"
        }
    },
    "production": {
        // It has a "." because JS is minifying the keys without the quotes
        "view.mediport.dev.qarts.eu": {
            host: "https://api.mediport.dev.qarts.eu",
            redirectUrl: "https://view.mediport.dev.qarts.eu",
            colorScheme: "#2b3618",
            synapseApplicationId: "api.mediport.dev.qarts.eu"
        },
        "view.mediris.dev.qarts.eu": {
            host: "https://api.mediris.dev.qarts.eu",
            redirectUrl: "https://view.mediris.dev.qarts.eu",
            colorScheme: "#bb19f7",
            synapseApplicationId: "api.mediris.dev.qarts.eu"
        },
        "view.ubecare.dev.qarts.eu": {
            host: "https://api.ubecare.dev.qarts.eu",
            redirectUrl: "https://view.ubecare.dev.qarts.eu",
            colorScheme: "#000000",
            synapseApplicationId: "api.ubecare.dev.qarts.eu"
        },
        "view.partheas.dev.qarts.eu": {
            host: "https://api.partheas.dev.qarts.eu",
            redirectUrl: "https://view.partheas.dev.qarts.eu",
            colorScheme: "#4666ff",
            synapseApplicationId: "api.partheas.dev.qarts.eu"
        },
        "view.cdg.dev.qarts.eu": {
            host: "https://api.cdg.dev.qarts.eu",
            redirectUrl: "https://view.cdg.dev.qarts.eu",
            colorScheme: "#d5b26d",
            synapseApplicationId: "api.cdg.dev.qarts.eu"
        },
        "view.ocs.dev.qarts.eu": {
            host: "https://api.ocs.dev.qarts.eu",
            redirectUrl: "https://view.ocs.dev.qarts.eu",
            colorScheme: "#1e73be",
            synapseApplicationId: "api.ocs.dev.qarts.eu"
        },
        "view.medicis.dev.qarts.eu": {
            host: "https://api.medicis.dev.qarts.eu",
            redirectUrl: "https://view.medicis.dev.qarts.eu",
            colorScheme: "#00326b",
            synapseApplicationId: "api.medicis.dev.qarts.eu"
        },
        "view.beukenlaan.dev.qarts.eu": {
            host: "https://api.beukenlaan.dev.qarts.eu",
            redirectUrl: "https://view.beukenlaan.dev.qarts.eu",
            colorScheme: "#8BBB57",
            synapseApplicationId: "api.beukenlaan.dev.qarts.eu"
        },
        "view.healthhub.dev.qarts.eu": {
            host: "https://api.healthhub.dev.qarts.eu",
            redirectUrl: "https://view.healthhub.dev.qarts.eu",
            colorScheme: "#00679A",
            synapseApplicationId: "api.healthhub.dev.qarts.eu"
        },
        "view.doclr.dev.qarts.eu": {
            host: "https://api.doclr.dev.qarts.eu",
            redirectUrl: "https://view.doclr.dev.qarts.eu",
            colorScheme: "#00679A",
            synapseApplicationId: "api.doclr.dev.qarts.eu"
        },
        "view.devaart.dev.qarts.eu": {
            host: "https://api.devaart.dev.qarts.eu",
            redirectUrl: "https://view.devaart.dev.qarts.eu",
            colorScheme: "#E5541B",
            synapseApplicationId: "api.devaart.dev.qarts.eu"
        },
        "PRODUCTION.TENANT_L": {
            host: "PRODUCTION.TENANT_L_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_L_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_L_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_L_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_M": {
            host: "PRODUCTION.TENANT_M_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_M_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_M_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_M_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_N": {
            host: "PRODUCTION.TENANT_N_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_N_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_N_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_N_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_O": {
            host: "PRODUCTION.TENANT_O_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_O_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_O_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_O_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_P": {
            host: "PRODUCTION.TENANT_P_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_P_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_P_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_P_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Q": {
            host: "PRODUCTION.TENANT_Q_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Q_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Q_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Q_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_R": {
            host: "PRODUCTION.TENANT_R_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_R_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_R_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_R_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_S": {
            host: "PRODUCTION.TENANT_S_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_S_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_S_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_S_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_T": {
            host: "PRODUCTION.TENANT_T_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_T_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_T_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_T_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_U": {
            host: "PRODUCTION.TENANT_U_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_U_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_U_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_U_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_V": {
            host: "PRODUCTION.TENANT_V_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_V_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_V_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_V_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_W": {
            host: "PRODUCTION.TENANT_W_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_W_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_W_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_W_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_X": {
            host: "PRODUCTION.TENANT_X_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_X_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_X_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_X_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Y": {
            host: "PRODUCTION.TENANT_Y_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Y_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Y_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Y_SYNAPSE_APP_ID"
        },
        "PRODUCTION.TENANT_Z": {
            host: "PRODUCTION.TENANT_Z_API_HOST",
            redirectUrl: "PRODUCTION.TENANT_Z_API_REDIRECT_URL",
            colorScheme: "PRODUCTION.TENANT_Z_COLOR_SCHEME",
            synapseApplicationId: "PRODUCTION.TENANT_Z_SYNAPSE_APP_ID"
        },
    }
}