<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('admin-settings')"></content-header>
      </div>
    </div>

    <div class="content-sidebars sidebars-60-40">
      <subMenus :menuItems="menuItems"></subMenus>
    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import subMenus from "../../common/SubMenus.vue";

export default {
  name: "AdminSettings",
  components: {ContentHeader, subMenus},

  data() {
    return {
      contentLoaded: false,
      menuItems: [
        {name: 'messages', icon: "fa-envelope-open", route: "messages", description: "manage-messages"},
        {name: 'reports', icon: "fas fa-list-alt", route: "reports", description: "manage-reports"}
      ],
    }
  },
  mounted() {
  },
  methods: {},
}

</script>