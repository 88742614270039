export default {
    onOpeningHourSideBarStatus(state) {
        return state.openingHourSideBarStatus;
    },
    onSelectedOpeningHour(state) {
        return state.selectedOpeningHour;
    },
    onSelectedDomain(state) {
        return state.selectedDomain;
    },
    onSideFormStatus(state) {
        return state.sideFormStatus;
    },
    getDomainTypes(state) {
        return state.domainTypes;
    },
    getStandardTexts(state) {
        return state.standardTexts;
    },
    onCreatedDomain(state) {
        return state.createdDomain;
    },
    onUpdatedDomain(state) {
        return state.updatedDomain;
    }
}