class DomainTimings {
  constructor(httpMethods) {
    this.api = httpMethods;
    this.EP = {
      GET_DOMAIN_TIMINGS: "/api/sites/:siteId/domains/:domainId/timings",
      CREATE_DOMAIN_TIMING: "/api/sites/:siteId/domains/:domainId/timings",
      CREATE_DOMAIN_TIMINGS_BY_REGIME: "/api/sites/:siteId/domains/:domainId/timings/regimes/:regimeId/timings",
      DELETE_DOMAIN_TIMING:
        "/api/sites/:siteId/domains/:domainId/timings/:domainTimingId",
      DELETE_DOMAIN_TIMINGS_BY_REGIME: "/api/sites/:siteId/domains/:domainId/timings/regimes/:regimeId/timings",
      UPDATE_DOMAIN_TIMING:
        "/api/sites/:siteId/domains/:domainId/timings/:domainTimingId",
      UPDATE_DOMAIN_TIMINGS_BY_REGIME: "/api/sites/:siteId/domains/{domainId}/timings/regimes/:regimeId/timings",
      CREATE_OR_UPDATE_DOMAIN_TIMINGS: "api/sites/:siteId/domains/:domainId/timings"
    };
  }

  getDomainTimings(domainId) {
    const url = this.EP.GET_DOMAIN_TIMINGS;
    return this.api.get(url, {
      domainId: domainId
    });
  }

  addDomainTiming(domainTimingRequest, domainId) {
    const url = this.EP.CREATE_DOMAIN_TIMING;

    return this.api.post(url, domainTimingRequest, {
      domainId: domainId
    });
  }

  deleteDomainTiming(domainId, domainTimingId) {
    const url = this.EP.DELETE_DOMAIN_TIMING;

    return this.api.del(url, {
      domainId: domainId,
      domainTimingId: domainTimingId
    });
  }

  deleteDomainTimingsByRegime(regimeId, query) {
    let url = this.EP.DELETE_DOMAIN_TIMINGS_BY_REGIME;
    if (query !== '') {
      url = url + '?' + query;
    }
    console.log(url);
    return this.api.del(url, {
      domainId: 1,
      regimeId: regimeId
    });
  }

  updateDomainTiming(updateRequest, domainId, domainTimingId) {
    const url = this.EP.DELETE_DOMAIN_TIMING;

    return this.api.put(url, updateRequest, {
      domainId: domainId,
      domainTimingId: domainTimingId
    });
  }

  updateDomainTimingsByRegime(updateRequest, regimeId, query) {
    let url = this.EP.UPDATE_DOMAIN_TIMINGS_BY_REGIME;
    if (query !== '') {
      url = url + '?' + query;
    }

    return this.api.put(url, updateRequest, {
      regimeId: regimeId
    })
  }

  createDomainTimingsByRegime(createRequest, domainId, regimeId) {
    const url = this.EP.CREATE_DOMAIN_TIMINGS_BY_REGIME;

    return this.api.post(url, createRequest, {
      domainId: domainId,
      regimeId: regimeId
    })
  }

}

export default DomainTimings;
