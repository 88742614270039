<template>
  <div
    class="w3-dropdown-click flow-select-list-container w-100pc"
    ref="flowSelect"
  >
    <div @click.prevent="openList = !openList" class="flex-row-select">
      <span
        class="w3-input cursor-pointer w3-padding flow-select-list-selected w3-ripple"
        v-bind:style="
          hasBackgroundColor
            ? { 'background-color': selectedItem.colorCode }
            : {}
        "
      >
        <p
          class="w-90pc w3-show-inline-block zero flow-no-wrap qpp-text-align-start"
        >
          {{ translation(selectedItem[valueKey]) }}
        </p>
      </span>
      <i
        class="fa fa-chevron-down w3-right flow-green-other-color"
        v-bind:class="openList ? 'fa-rotate-180' : ''"
      ></i>
      <div class="select-container"></div>
    </div>
    <div
      v-bind:class="openList ? 'w3-show' : 'w3-hide'"
      class="w3-dropdown-content w3-bar-block w3-border w-100pc flow-select-list flow-card w3-animate-opacity"
    >
      <span
        v-for="item in items"
        v-bind:key="item.id"
        v-bind:class="
          item[valueKeyIndex] === selectedItem[valueKeyIndex] ? 'selected' : ''
        "
        v-bind:style="
          hasBackgroundColor ? { 'background-color': item.colorCode } : {}
        "
        class="w3-bar-item cursor-pointer flow-select-item"
        @click="selectItem(item)"
      >
        <span v-bind:class="item[valueKey] ? '' : 'null-value'">{{
          translation(item[valueKey])
        }}</span>
        <i
          class="fa fa-check flow-green-active-color w3-right"
          v-if="!hasBackgroundColor"
        ></i>
      </span>
    </div>
  </div>
</template>

<script>
//TODO: Fix the arrow icon animation and adjust the responsiveness a bit
export default {
  /**
   * Reusable dropdown box that goes well together with the grid components "Row" and "Column".
   */
  name: "StandardSelect",
  props: {
    /**
     * the options you can choose from
     */
    items: undefined,
    /**
     * example:
     * {
     *   key: "MONDAY",
     *   value: "Maandag"
     * }
     * value --> valueKey
     */
    valueKey: {
      type: String,
      default: ""
    },
    /**
     * example:
     * option1:
     * {
     *   key: "MONDAY",
     *   value: "Maandag"
     * }
     * key --> valueKeyIndex
     */
    valueKeyIndex: {
      type: String,
      default: ""
    },
    mainItem: undefined,
    callback: undefined,
    preSelectedItem: undefined,
    hasBackgroundColor: {
      type: Boolean,
      default: false
    },
    translate: {
      type: Boolean,
      default: false
    }
  },
  created() {
    document.addEventListener("click", this.documentClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.documentClick);
  },
  data() {
    return {
      openList: false,
      selectedItem: {}
    };
  },
  watch: {
    items() {
      this.setup();
    },
    preSelectedItem() {
      this.setup();
    }
  },
  mounted() {
    this.setup();
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
      if (this.callback) {
        if (this.mainItem) this.callback(item, this.mainItem);
        else this.callback(item);
      }
      this.openList = false;
    },
    setup() {
      if (this.preSelectedItem) {
        this.selectItem(this.preSelectedItem);
      } else if (this.items && this.items.length > 0) {
        this.selectItem(this.items[0]);
      }
    },
    documentClick(e) {
      const app = this;
      const tp = app.$refs.flowSelect;
      if (!tp || !tp.contains(e.target)) {
        this.openList = false;
      }
    },
    translation(text) {
      if (this.translate && text) {
        return this.$t(text);
      } else return text;
    }
  }
};
</script>

<style scoped>
.flex-row-select {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; /* Align items at the start of the container */
  align-items: center; /* Align items to the top */
}

.select-container {
  width: 10px;
}
</style>
