import nl from './languages/nl.json'
import en from './languages/en.json'
import {createI18n} from 'vue-i18n'

const locale = 'nl';
const messages = {
    nl: nl,
    en: en
}

const i18n = new createI18n({
    locale,
    messages
})

export default i18n
