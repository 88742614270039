<template>
  <div class="flow-padding-bottom zero-top w-100pc flow-flex-col w3-left">
    <label class="w3-left flow-blue-color flow-padding-bottom">{{
      $t(labelName)
    }}</label>
    <input
      v-show="!isDisabled"
      class="w3-input w3-border w3-round-medium"
      v-bind:type="isNumber? 'number' : 'text'"
      v-model="internalText"
      @input="updateText"
      v-bind:placeholder="placeholder"
    />
    <input
      v-show="isDisabled"
      class="w3-input w3-border w3-round-medium"
      type="text"
      v-model="internalText"
      @input="updateText"
      v-bind:placeholder="placeholder"
      disabled
    />
    <span class="w3-text-red">{{ npNameError }}</span>
  </div>
</template>
<script>
export default {
  name: "StandardTextBox",
  emits: ["updateText"],
  props: {
    npNameError: {
      type: String,
      required: false,
      default: ""
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    text: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    labelName: {
      type: String,
      required: false,
      default: ""
    },
    isNumber: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      /**
       * We need an internal text in this component because mutating props is not allowed.
       * @string
       */
      internalText: "",
      internalNpNameError: ""
    };
  },
  methods: {
    /**
     * This emits the internalText when you edit the text in the input
     */
    updateText() {
      this.$emit('updateText', this.internalText);
    }
  },
  mounted() {
    this.internalText = this.text;
  },
  watch: {
    text() {
      this.internalText = this.text;
    }
  }
};
</script>
