<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('site-settings')"></content-header>
      </div>
    </div>

    <div class="content-sidebars sidebars-60-40">
      <subMenus :menuItems="menuItems"></subMenus>
    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import subMenus from "../../common/SubMenus.vue";

export default {
  name: "SiteSettings",
  components: {ContentHeader, subMenus},

  data() {
    return {
      contentLoaded: false,
      menuItems: [
        {name: 'desks', icon: "fa-desktop", route: "desks", description: "manage-desks"},
        {name: 'printers', icon: "fa-print", route: "printers", description: "manage-printers"},
        {name: 'waiting-rooms', icon: "fa-door-open", route: "waiting-rooms", description: "manage-waiting-rooms"}
      ],
    }
  },
  mounted() {
  },
  methods: {},
}

</script>