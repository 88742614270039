<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-blue-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('editStText')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">

        <div class="w3-container zero-bottom w3-block flow-form-inline-input">
          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("type") }}</label>
            <flow-select :callback="setSelectedType" :items="typeList"
                         :valueKey="'name'"
                         :valueKeyIndex="'name'"
                         class="w3-border w3-round-medium"
                         type="text">
            </flow-select>
          </p>

          <span v-if="npMessageError === false" class="w3-text-red">{{ $t("add-locale-warning-message") }}</span>
          <span v-if="npPlaceholderError" class="w3-text-red">{{ $t("wrong-placeholder") }}</span>

          <div v-for="language in languages" :key="language.id">
            <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
              <label class="w3-left flow-blue-color flow-padding-bottom">{{ language.isoCode }}</label>
              <textarea :value="standardTexts[language.id]" class="w3-input w3-border w3-round-medium"
                        rows="4" type="text"
                        @input="setValue($event.target.value, language.id)"/>
            </p>
          </div>
        </div>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="updateStText">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";

export default {
  name: "EditStandardText",
  components: {ContentHeader, FlowSelect},
  emits: ['updatedStText'],
  props: ['onClose', 'placeholders', 'languages', 'currentStText'],
  data() {
    return {
      standardTexts: {},
      placeholderKeys: [],
      typeList: [
        {
          name: 'Instruction',
          id: 'instruction'
        }
      ],
      npMessageError: null,
      npPlaceholderError: null
    }
  },
  watch: {
    currentStText() {
      if (this.currentStText != null && this.currentStText.key != null){
        this.getAllStandardTextsByKey();
        this.getStandardTextType();
      }
    }
  },
  mounted() {
    if (this.currentStText != null && this.currentStText.key != null)
      this.getAllStandardTextsByKey();

    this.placeholderKeys = this.placeholders.map(function (value) {
      return value.name.slice(1,-1);
    });
    this.getStandardTextType();
  },

  methods: {
    getStandardTextType(){
      const app = this;
      app.typeList = [{name: app.currentStText.type,id:app.currentStText.type}];
    },
    setSelectedType(option) {
      option; // not capturing type for now
    },

    getAllStandardTextsByKey() {
      const app = this;
      app.$restClient.languages.getStandardTextsByUuid(this.currentStText.key).then(response => {
        let data = response.data;
        app.standardTexts = {};
        if (data.length > 0)
          data.forEach(i => {
            this.standardTexts[i.languageId] = i.value;
          });
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title:this.$t("failed")});
      });
    },

    updateStText() {
      if (!this.npMessageError || this.npPlaceholderError) return;
      const app = this;
      app.$restClient.languages.editStandardTexts( {
        key: this.currentStText.key,
        values: this.standardTexts
      }).then(response => {
        let data = response.data;
        this.$emit("updatedStText", data);
        app.onClose;
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")})
      });
    },

    setValue(value, languageId) {
      this.npPlaceholderError = false;
      if (value.trim() === '') return;

      var regExp = /{(.*?)}/g;
      var matches = [...value.matchAll(regExp)].map(match => match[1]);
      let missingPlaceholders = matches.filter(x => !this.placeholderKeys.includes(x));
      if (missingPlaceholders.length > 0)
        this.npPlaceholderError = true;

      if (this.standardTexts[languageId] == null)
        this.standardTexts[languageId] = '';
      this.standardTexts[languageId] = value.trim();
      this.npMessageError = true;
    }

  }
}
</script>