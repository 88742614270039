class Languages {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_DEFAULT_LANGUAGE: '/api/sites/:siteId/languages/default',
            GET_ALL_ACTIVE_LANGUAGES: '/api/sites/:siteId/languages',
            REORDER_LANGUAGES: '/api/sites/:siteId/languages',
            GET_ALL_STANDARD_TEXTS: '/api/sites/:siteId/standard-texts',
            GET_ALL_LOCALES_FOR_DEFAULT_LANGUAGE: '/api/sites/:siteId/standard-texts/default-language',
            GET_STANDARD_TEXTS_BY_UUID: '/api/sites/:siteId/standard-texts/uuid/:uuid',
            ADD_UPDATE_STANDARD_TEXTS: '/api/sites/:siteId/standard-texts',
            DELETE_STANDARD_TEXTS: '/api/sites/:siteId/standard-texts/:languageId/:key',
            GET_ALL_PLACEHOLDERS: '/api/sites/:siteId/placeholders',
            GET_ALL_TYPES: '/api/sites/:siteId/standard-texts/types'
        };
    }

    getDefaultLanguage(){
        return this.api.get(this.EP.GET_DEFAULT_LANGUAGE);
    }

    getStandardTextTypes(){
        return this.api.get(this.EP.GET_ALL_TYPES);
    }

    all() {
        return this.api.get(this.EP.GET_ALL_ACTIVE_LANGUAGES);
    }

    allLocalesForDefaultLanguage(){
        return this.api.get(this.EP.GET_ALL_LOCALES_FOR_DEFAULT_LANGUAGE);
    }
    allDomainLocalesForDefaultLanguage(){
        return this.api.get(this.EP.GET_ALL_LOCALES_FOR_DEFAULT_LANGUAGE,{},{type:"MESSAGE"});
    }

    reorder(data) {
        return this.api.put(this.EP.REORDER_LANGUAGES , data);
    }

    getStandardTexts() {
        return this.api.get(this.EP.GET_ALL_STANDARD_TEXTS);
    }

    getStandardTextsByUuid(data) {
        return this.api.get(this.EP.GET_STANDARD_TEXTS_BY_UUID,{
            uuid: data
        });
    }

    addStandardTexts(data) {
        return this.api.post(this.EP.ADD_UPDATE_STANDARD_TEXTS, data);
    }

    editStandardTexts(data) {
        return this.api.put(this.EP.ADD_UPDATE_STANDARD_TEXTS, data);
    }

    deleteStandardTexts(data) {
        return this.api.del(this.EP.DELETE_STANDARD_TEXTS, data);
    }

    getPlaceholders() {
        return this.api.get(this.EP.GET_ALL_PLACEHOLDERS);
    }

}

export default Languages;