import eventHandlerMutations from './mutations.js';
import eventHandlerActions from './actions.js';
import eventHandlerGetters from './getters.js';

export default {
    namespaced: true,
    state() {
        return {
            openingHourSideBarStatus: "",
            selectedOpeningHour: {},
            selectedDomain: {},
            sideFormStatus: "close",
            domainTypes: [],
            standardTexts: [],
            createdDomain: {},
            updatedDomain: {}
        };
    },
    mutations: eventHandlerMutations,
    actions: eventHandlerActions,
    getters: eventHandlerGetters
};