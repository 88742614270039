<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-blue-color w3-xlarge w3-margin-right"></i>
    <content-header v-bind:title="$t('addNewStText')"></content-header>

    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <div class="w3-container zero-bottom w-90pc w3-show-inline-block flow-form-inline-input">

        <div class="w3-container zero-bottom w3-block flow-form-inline-input">
          <p class="flow-padding-bottom zero-top w-70pc">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{ $t("type") }}</label>
            <flow-select :callback="setSelectedType" :items="typeList"
                         :valueKey="'name'"
                         :valueKeyIndex="'name'"
                         :translate="true"
                         class="w3-border w3-round-medium"
                         type="text">
            </flow-select>
          </p>

          <span v-if="npMessageError === false" class="w3-text-red">{{ $t("add-locale-warning-message") }}</span>
          <span v-if="npPlaceholderError" class="w3-text-red">{{ $t("wrong-placeholder") }}</span>

          <div v-for="language in languages" :key="language.id">
            <p class="flow-padding-bottom zero-top w-90pc flow-flex-col w3-left">
              <label class="w3-left flow-blue-color flow-padding-bottom">{{ language.isoCode }}</label>
              <textarea class="w3-input w3-border w3-round-medium" rows="4"
                        type="text" @input="setValue($event.target.value, language.id)"/>
            </p>
          </div>
        </div>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top w3-margin-right"
                @click.prevent="addNewStText">
          <i class="fa fa-save flow-green-active-color">
            <span><b class="flow-margin-left">{{ $t('save') }}</b></span>
          </i>
        </button>

        <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-top"
                @click.prevent="onClose">
          <i class="fa fa-arrow-left w3-text-red">
            <span><b class="flow-margin-left">{{ $t('back') }}</b></span>
          </i>
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import FlowSelect from "@/components/common/FlowSelect";

export default {
  name: "NewStandardText",
  components: {ContentHeader, FlowSelect},
  emits: ['updatedStText'],
  props: ['onClose', 'placeholders', 'languages'],

  data() {
    return {
      newStText: {},
      placeholderKeys: [],
      typeList: [
        {
          name: 'INSTRUCTION',
          id: 'instruction'
        }
      ],
      npMessageError: null,
      npPlaceholderError: null,
      defaultLanguage: null
    }
  },
  mounted() {
    this.placeholderKeys = this.placeholders.map(function (value) {
      return value.name.slice(1, -1);
    });
    this.getStandardTextTypes();
    this.getDefaultLanguage();
  },
  methods: {
    getStandardTextTypes() {
      const app = this;
      app.$restClient.languages.getStandardTextTypes().then(response => {
        app.typeList = response.data;
      })
    },

    addNewStText() {
      if (!this.npMessageError) return;

      const {id} = this.defaultLanguage;
      const {locales, type} = this.newStText;

      if (!Object.prototype.hasOwnProperty.call(locales, id)) {
        this.$notify.notifyError({
          title: this.$t("failed"),
          message: this.$t('no-default-text', {language: this.defaultLanguage.name})
        });
        return;
      }

      const defaultStdText = {type, locales: {[id]: locales[id]}};

      this.$restClient.languages.addStandardTexts(defaultStdText)
          .then(response => {
            const key = response.data[0].key;
            return this.$restClient.languages.editStandardTexts({key, values: locales});
          })
          .then(response => {
            this.$notify.notifySuccess({title: this.$t("success"), message: this.$t('added-success')});
            this.$emit("updatedStText", response.data);
          })
          .catch(err => {
            console.log(err);
            const message = err.response?.status === 400 ? this.$t('duplicate-st-text') : '';
            this.$notify.notifyError({title: this.$t("failed"), message});
          });
    },

    getDefaultLanguage() {
      const app = this;
      app.$restClient.languages.getDefaultLanguage().then(response => {
        app.defaultLanguage = response.data;
      })
    },

    setSelectedType(option) {
      this.newStText.type = option.name;
    },

    setValue(value, languageId) {
      this.npPlaceholderError = false;
      if (value.trim() === '') return;

      const regExp = /{(.*?)}/g;
      const matches = [...value.matchAll(regExp)].map(match => match[1]);
      const missingPlaceholders = matches.filter(x => !this.placeholderKeys.includes(x));

      if (missingPlaceholders.length > 0) {
        this.npPlaceholderError = true;
        return;
      }

      if (!this.newStText["locales"]) {
        this.newStText["locales"] = {};
      }

      this.newStText["locales"][languageId] = value.trim();
      this.npMessageError = true;
    }
  }
}
</script>