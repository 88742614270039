const Helper = {
    sortByKey(array, key, key2, order = 'asc') {
        if (array && array.length > 1) {
            array.sort(function (a, b) {
                let comparison = 0;

                // Handle null values
                if (a[key] === null) {
                    return order === 'asc' ? -1 : 1;
                } else if (b[key] === null) {
                    return order === 'asc' ? 1 : -1;
                }

                // Compare the values based on the order
                if (order === 'asc') {
                    comparison = a[key].localeCompare(b[key]);
                    if (key2) comparison = (a[key] + a[key2]).localeCompare(b[key] + b[key2]);
                } else {
                    comparison = b[key].localeCompare(a[key]);
                    if (key2) comparison = (b[key] + b[key2]).localeCompare(a[key] + a[key2]);
                }
                return comparison;
            });
        }
    },

    setCookie(key, value, expiryInMins) {
        let now = new Date();
        let expiry;
        if (expiryInMins) {
            now.setTime(now.getTime() + (expiryInMins * 60 * 1000))
            expiry = now.toGMTString();
        } else {
            expiry = 'Fri, 31 Dec 9999 23:59:59 GMT';
        }
        document.cookie = key + "=" + value + ";expires=" + expiry + ";path=/";
    },

    getCookie(key) {
        let pattern = "(?:(?:^|.*;\\s*)" + key + "\\s*\\=\\s*([^;]*).*$)|^.*$";
        let regex = new RegExp(pattern, 'g');
        return document.cookie.replace(regex, "$1");
    },

    getTime(_date) {
        let date = new Date(_date);
        let hours = date.getHours();
        let minutes = date.getMinutes();
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return hours + ':' + minutes;
    },
    getDate(dStr, format) {
        var now = new Date();
        if (format === "h:m") {
            now.setHours(dStr.substr(0, dStr.indexOf(":")));
            now.setMinutes(dStr.substr(dStr.indexOf(":") + 1));

            var yyyy = now.getFullYear();
            var MM = now.getMonth() + 1;
            var dd = now.getDate();
            var hh = now.getHours();
            var mm = now.getMinutes();
            var d = yyyy + '-' + (MM < 10 ? '0' + MM : MM) + '-' + (dd < 10 ? '0' + dd : dd) + ' ' + (hh < 10 ? '0' + hh : hh) + ':' + (mm < 10 ? '0' + mm : mm) + ":00";
            return d;
        } else
            return "Invalid Format";
    },
    getDateAsYYYYMMDD(date) {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    },
    /**
     * checks if time is of format HH:mm
     * @example 08:00 -> valid, 8:00 -> invalid, 08:00f -> invalid
     * @param time
     */
    isTimeValid(time) {
        const pattern = new RegExp("\\b([01]\\d|2[0-3]):[0-5]\\d\\b");
        return pattern.test(time);
    },

    validateUrl(url){
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(url);
    },
    validatePhone(phone){
        const pattern = new RegExp('^\\+?([0-9]{2})\\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$','i'); // fragment locator
        const pattern1 = new RegExp('\\+324[0-9]{8}','g'); // fragment locator
        return !!pattern.test(phone) || !!pattern1.test(phone);
    },
    NumberWithLeadingZeros(number,desiredLength){
        let numAsString = number.toString();
        while (numAsString.length < desiredLength) numAsString = "0" + numAsString;
        return numAsString;
    },
    sanitizeHTML(inputData){
        const sanitizedString = inputData ? inputData.replace(/\\r/g, '\r')
                                         .replace(/\\n/g, '\n')
                                         .replace(/&nbsp;/g, ' ')
                                         .replace(/nbsp;/g, ' ') : inputData; // Replace &nbsp;

        return sanitizedString;

    },
    truncate(message, maxLength){
        if(message) {
            if (message.length > maxLength)
                return message.substring(0, maxLength) + "(...)";
            else {
                return message;
            }
        } else return "";
    },
    extractHexString(input) {
        const match = input.match(/CKA_VALUE\s*=\s*([0-9A-Fa-f]+)/);
        return match ? match[1] : null;
    }
};

export default {
    install: (app) => {
        app.config.globalProperties.$helper = Helper
    }
}