class Domains {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_DOMAINS: '/api/sites/:siteId/domains',
            ADD_DOMAIN: '/api/sites/:siteId/domains',
            GET_UPDATE_DELETE_DOMAIN: '/api/sites/:siteId/domains/:domainId'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_DOMAINS);
    }

    add(data,userid) {
        let notificationTextKeyValue = "";
        if(data.notificationText && data.notificationText.key){
            notificationTextKeyValue = data.notificationText.key;
        }
        return this.api.post(
            this.EP.ADD_DOMAIN,
            {
                name: data.name,
                type: data.type.id,
                closingTimeMinutes: data.closingTimeMinutes,
                notificationTextKey:notificationTextKeyValue,
                createdBy: userid,
                updatedBy: userid
            }
        );
    }
    update(data,userId) {
        let notificationTextKeyValue = "";
        if(data.notificationText && data.notificationText.key){
            notificationTextKeyValue = data.notificationText.key;
        }
        return this.api.put(
            this.EP.GET_UPDATE_DELETE_DOMAIN,
            {
                name: data.name,
                type: data.type.id,
                prefix: data.prefix,
                closingTimeMinutes: data.closingTimeMinutes,
                notificationTextKey: notificationTextKeyValue,
                createdBy: userId,
                updatedBy: userId
            }, {
                domainId: data.id
            }
        );
    }

    get(domainId) {
        return this.api.get(
            this.EP.GET_UPDATE_DELETE_DOMAIN,
            {
                domainId: domainId
            }
        );
    }

    delete(domainId) {
        return this.api.del(
            this.EP.GET_UPDATE_DELETE_DOMAIN,
            {
                domainId: domainId
            }
        );
    }
}

export default Domains;