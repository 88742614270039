<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('kiosk-settings')"></content-header>
      </div>
    </div>

    <div class="content-sidebars sidebars-60-40">
      <subMenus :menuItems="menuItems"></subMenus>
    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import subMenus from "../../common/SubMenus.vue";

export default {
  name: "kioskSettings",
  components: {ContentHeader, subMenus},

  data() {
    return {
      contentLoaded: false,
      menuItems: [
        {name: 'languages', icon: "fa-language", route: "languages", description: "manage-languages"},
        {name: 'opening-hours',icon: "fa-regular fa-clock",route: "opening-hours",description: "manage-opening-hours"},
        {name: 'standard-texts', icon: "fa-align-left", route: "standard-texts", description: "manage-standard-texts"},
        {name: 'domains', icon: "fa-inbox", route: "domains", description: "manage-domains"},
        {name: 'walking-instructions',icon: "fa-arrows-alt",route: "instructions",description: "manage-walking-instructions"}
      ],
    }
  },
  mounted() {
  },
  methods: {},
}

</script>