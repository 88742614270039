<template>
  <div class="w3-col w3-left-align">
    <ContentHeader v-bind:title="$t('printers')" />
    <!--        view for + icon-->
    <div class="element-container w3-right w3-margin-right flow-margin-top">
      <PlusButton />
    </div>
    <!--        end view for + icon-->
  </div>
  <div class="content-sidebars sidebars-70-30">
    <SideForm
      class="absolute-container"
      v-if="openForm()"
      :context="onSideFormStatus"
    >
      <template #create>
        <CreatePrinter @success="onSuccess" @failure="onFailed" />
      </template>
    </SideForm>

    <div class="w3-container w3-margin-bottom zero">
      <Loading v-bind:dspin="printerContentLoading" />
    </div>
    <PrinterTable
      v-show="!printerContentLoading"
      :move-table-left="openForm()"
      :new-printer-added="newPrinterAdded"
      @success="onSuccess"
      @failure="onFailed"
    />
  </div>
</template>

<script>
import SideForm from "@/components/common/SideForm.vue";
import { mapActions, mapGetters } from "vuex";
import PlusButton from "@/components/common/buttons/PlusButton.vue";
import ContentHeader from "@/components/common/ContentHeader.vue";
import Loading from "@/components/common/Loading.vue";
import PrinterTable from "@/components/views/printers/PrinterTable.vue";
import CreatePrinter from "@/components/views/printers/CreatePrinter.vue";

export default {
  name: "Printers",
  components: {
    CreatePrinter,
    PrinterTable,
    Loading,
    ContentHeader,
    PlusButton,
    SideForm
  },
  data() {
    return {
      printerContentLoading: false,
      selectedPrinterService: {},
      newPrinterAdded: false
    };
  },
  computed: {
    ...mapGetters({
      onSideFormStatus: "eventHandlers/onSideFormStatus"
    })
  },
  methods: {
    ...mapActions({
      sideBarStatusEvent: "eventHandlers/sideFormStatusEvent"
    }),
    openForm() {
      return this.onSideFormStatus === "create";
    },
    onSuccess(printer) {
      this.$notify.notifySuccess({
        title: this.$t("success")
      });
      this.newPrinterAdded = printer;
      this.sideBarStatusEvent("close");
    },
    onFailed(err) {
      let message = "";
      if (err.response.status === 500) message = err.response.status;
      else if (err.response.data.message === undefined)
        message = this.$t("FAILED_TO_UPDATE");
      else message = this.$t(err.response.data.message);
      this.$notify.notifyError({
        title: this.$t("failed"),
        message: message
      });
    }
  },
  created() {
    this.sideBarStatusEvent("close");
  }
};
</script>

<style scoped></style>
