<template>
  <button
    v-show="!isDisabled"
    class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-right"
    @click.once="onDelete"
  >
    <i class="fa fa-trash w3-text-deep-orange">
      <span
        ><b class="flow-margin-left">{{ $t("delete") }}</b></span
      >
    </i>
  </button>
  <button
    v-show="isDisabled"
    class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium w3-margin-right"
    disabled
  >
    <i class="fa fa-trash w3-text-deep-orange">
      <span
        ><b class="flow-margin-left">{{ $t("delete") }}</b></span
      >
    </i>
  </button>
</template>
<script>
export default {
  name: "DeleteButton",
  props: {
    /**
     * callback function
     */
    onDelete: {
      type: Function,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>
