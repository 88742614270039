import axios from 'axios';
import Desks from './api/Desks';
import Printers from './api/Printers';
import Domains from "./api/Domains";
import Sites from './api/Sites';
import Languages from "./api/Languages";
import Messages from "./api/Messages";
import RestClientConfig from "@/plugins/restClient/restClientConfig";
import DomainTypes from "@/plugins/restClient/api/DomainTypes";
import WaitingRooms from "@/plugins/restClient/api/WaitingRooms";
import WalkingInstructions from "@/plugins/restClient/api/WalkingInstructions";
import QppUsers from "@/plugins/restClient/api/QppUsers";
import Regimes from "@/plugins/restClient/api/Regimes";
import Authentication from "@/plugins/restClient/api/Authentication";
import DeskDetails from "@/plugins/restClient/api/DeskDetails";
import Visits from "@/plugins/restClient/api/Visits";
import Configuration from "@/plugins/restClient/api/Configurations";
import Visitors from "@/plugins/restClient/api/Visitors";
import DomainTimings from "@/plugins/restClient/api/DomainTimings";


export default {
    install: (app, options) => {
        axios.interceptors.response.use(options.responseInterceptor);
        axios.defaults.withCredentials = true;
        let clientConfig = new RestClientConfig(options.apiConfig);
        let sites = new Sites(clientConfig);
        let desks = new Desks(clientConfig);
        let printers = new Printers(clientConfig);
        let visits = new Visits(clientConfig);
        let deskDetails = new DeskDetails(clientConfig);
        let domains = new Domains(clientConfig);
        let languages = new Languages(clientConfig);
        let messages = new Messages(clientConfig);
        let waitingRooms = new WaitingRooms(clientConfig);
        let walkingInstructions = new WalkingInstructions(clientConfig);
        let qppUsers = new QppUsers(clientConfig);
        let domainTypes = new DomainTypes(clientConfig);
        let authentication = new Authentication(clientConfig);
        let regimes = new Regimes(clientConfig);
        let configuration = new Configuration(clientConfig);
        let visitors = new Visitors(clientConfig);
        const domainTimings = new DomainTimings(clientConfig);

        app.config.globalProperties.$restClient = {
            sites,
            desks,
            printers,
            deskDetails,
            domains,
            domainTypes,
            qppUsers,
            languages,
            messages,
            waitingRooms,
            walkingInstructions,
            authentication,
            visits,
            regimes,
            configuration,
            visitors,
            domainTimings
        };
    }
}