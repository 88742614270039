<template>
  <div>
    <div id="notifications"></div>
    <router-view :key="$route.params.siteId"/>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
  @import url(~@fortawesome/fontawesome-free/css/all.min.css);
</style>
