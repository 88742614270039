<template>
    <div>
        <side-menu></side-menu>
        <div id="flow-content">
            <a id="scroll-to-top" href="#" v-if="scrolled" class="w3-animate-opacity-slow w3-left zero-bottom">
                <i class="fa fa-3x fa-chevron-circle-up w3-text-deep-orange flow-padding-left flow-padding-right w3-round-large text-shadow" title="Go to top"></i>
            </a>
            <router-view/>
        </div>

    </div>
</template>

<script>
    import SideMenu from './SideMenu.vue'

    export default {
        name: 'Layout',
        components: {
            SideMenu
        },data() {
            return{
                scrolled: false
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
            this.handleScroll();
        },
        unmounted() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                this.scrolled = window.pageYOffset > 200;
            }
        }
    }
</script>

<style>
#app {
  font-family: Caladea, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
img {
  height: 220px;
  width: 200px;
}
#flow-content{
  text-align: center;
}
</style>