class Printers {

    constructor(httpMethods) {
        this.api = httpMethods;
        this.EP = {
            GET_ALL_PRINTERS: '/api/sites/:siteId/printers',
            ADD_PRINTER: '/api/sites/:siteId/printers',
            GET_PRINTER: '/api/sites/:siteId/printers/:printerId',
            UPDATE_PRINTER: '/api/sites/:siteId/printers/:printerId',
            DELETE_PRINTER: '/api/sites/:siteId/printers/:printerId'
        };
    }

    all() {
        return this.api.get(this.EP.GET_ALL_PRINTERS);
    }

    add(data) {
        return this.api.post(
            this.EP.ADD_PRINTER,
            {
                name: data.name,
                ipDomain: data.ipDomain,
                port: data.port,
                printService: data.printService
            }
        );
    }

    update(data) {
        return this.api.put(
            this.EP.UPDATE_PRINTER,
            {
                name: data.name,
                ipDomain: data.ipDomain,
                port: data.port,
                printService: data.printService
            },
            {
                printerId: data.printerId
            }
        );
    }

    get(printerId) {
        return this.api.get(
            this.EP.GET_PRINTER,
            {
                printerId: printerId
            }
        );
    }

    delete(printerId) {
        return this.api.del(
            this.EP.DELETE_PRINTER,
            {
                printerId: printerId
            }
        );
    }
}

export default Printers;